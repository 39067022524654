import React from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Form, Input, Button } from "antd";

import { errorNotification } from "components/result";
import { Localize } from "components/service";

import "./login-form.css";

const LOGIN_USER = gql`
  mutation LoginUser($data: LoginInput!) {
    loginUser(input: $data) {
      access_token
    }
  }
`;

const formSubmit = ({ event, form, loginRequest }) => {
  event.preventDefault();

  form.validateFields((err, values) => {
    if (!err) {
      loginRequest({
        variables: {
          data: {
            username: values.username,
            password: values.password,
          },
        },
      });
    }
  });
};

const NormalLoginForm = (props) => {
  const { form, loginLoading, loginRequest, logo: Logo } = props;
  const { getFieldDecorator } = form;

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <div className="form-holder">
      <Logo />
      <h2>
        <Localize>FORMS.Title_Login</Localize>
      </h2>

      <Form
        className="login-form"
        onSubmit={(event) => {
          formSubmit({ event, form, loginRequest });
        }}
      >
        <Form.Item
          label={
            <span className="site-label-text-color">
              <Localize>FORMS.Input_Label_Username</Localize>
            </span>
          }
          {...formItemLayout}
        >
          {getFieldDecorator("username", {
            rules: [
              {
                required: true,
                message: <Localize>FORM_RULES.Required_Username</Localize>,
              },
            ],
          })(<Input name="login" />)}
        </Form.Item>
        <Form.Item
          label={
            <span className="site-label-text-color">
              <Localize>FORMS.Input_Label_Password</Localize>
            </span>
          }
          {...formItemLayout}
        >
          {getFieldDecorator("password", {
            rules: [
              {
                required: true,
                message: <Localize>FORM_RULES.Required_Password</Localize>,
              },
            ],
          })(<Input.Password type="password" name="password" />)}
        </Form.Item>
        <Form.Item>
          <div className="form-actions">
            <Link to="/forgot-password/" className="login-form-link">
              <Localize>FORMS.Link_Text_ForgotPassword</Localize>
            </Link>
            <Button
              type="primary"
              htmlType="submit"
              className="rounded-button"
              loading={loginLoading}
            >
              <Localize wrap>FORMS.Button_Text_Login</Localize>
            </Button>
          </div>
        </Form.Item>
      </Form>
      <div className="login-form-footer">
        <p>
          <Localize>GLOBAL.Text_Term</Localize>{" "}
          <a
            href="https://api.gwm-club.de/storage/20250206_f4m_gwm_agb_club_FINAL_2025.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <strong>
              <Localize>GLOBAL.Text_TermCondition</Localize>
            </strong>
          </a>
        </p>
      </div>
    </div>
  );
};

const LoginFormContainer = (props) => {
  const [loginRequest, { loading }] = useMutation(LOGIN_USER, {
    update(cache, { data: { loginUser } }) {
      const { access_token } = loginUser;

      localStorage.setItem("authToken", access_token);
      cache.writeData({ data: { authToken: access_token } });
    },
    onError(error) {
      errorNotification(error);
    },
  });

  return (
    <NormalLoginForm
      {...props}
      loginRequest={loginRequest}
      loginLoading={loading}
    />
  );
};

const LoginForm = Form.create({ name: "authorization" })(LoginFormContainer);

export default LoginForm;
